@font-face {
    font-family: 'Druk Text Wide Web Medium';
    src: url('https://db.onlinewebfonts.com/t/3724259186746d86859fbb0fde0fa44a.eot'); /* IE9*/
    src: url('https://db.onlinewebfonts.com/t/3724259186746d86859fbb0fde0fa44a.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('https://db.onlinewebfonts.com/t/3724259186746d86859fbb0fde0fa44a.woff2')
            format('woff2'),
        /* chrome firefox */
            url('https://db.onlinewebfonts.com/t/3724259186746d86859fbb0fde0fa44a.woff')
            format('woff'),
        /* chrome firefox */
            url('https://db.onlinewebfonts.com/t/3724259186746d86859fbb0fde0fa44a.ttf')
            format('truetype'),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/
            url('https://db.onlinewebfonts.com/t/3724259186746d86859fbb0fde0fa44a.svg#Druk Text Wide Web Medium')
            format('svg'); /* iOS 4.1- */
}
:root {
    --verde: #1dea87;
    --negro: #111111;
    --verdeHover: #5eeaa6;
    --gris: #3d3d3d;
    --gris2: #6f6f6f;
    --gris3: #e1e1e1;
    --naranja: #da552f;
}
html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body {
    line-height: 1.5;
    font-family: 'PT Sans', sans-serif;
}
h1,
h2,
h3 {
    margin: 0 0 0.5rem 0;
    line-height: 1.35;
    font-family: 'Druk Text Wide Web Medium', serif;
}
h1 {
    font-size: 1.4rem;
}
h2 {
    font-size: 1.3rem;
}
h3 {
    line-height: 1.7rem;
    font-size: 1.2rem;
}
h4 {
    font-size: 1rem;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: #111111;
}
a:hover {
    text-decoration: none;
    color: #111111;
}
img {
    max-width: 100%;
}

.hero-img {
    max-height: 80vh;
    width: 100%;
}
.aside-home {
    line-height: 1.3rem;
    padding: 2rem;
    margin: 0.5rem;
    text-align: center;
}
.bg-white {
    background-color: #fff;
}
.main-header {
    padding-bottom: 56px;
}

.navbar-main img {
    height: 25px;
    margin: 10px 0 10px 0;
}
.fitcontent-width {
    min-width: fit-content;
}

.logo:hover {
    cursor: pointer;
}
.responsive-navbar {
    flex-direction: column !important;
    width: 300px;
    margin-top: 20px;
    text-align: center;
    line-height: 2rem;
}

.responsive-navbar a {
    color: inherit;
    text-transform: uppercase;
}
.responsive-navbar img {
    height: 35px;
    max-width: 100%;
    margin: 0 auto 20px auto;
}
.bg-rondafit {
    background-color: var(--verde);
    color: #111111;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    margin-bottom: 1.5rem;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
footer {
    padding: 2rem 0;
    background-color: #111111;
    min-height: 100px;
    color: #e1e1e1;
}
.logo-footer {
    margin-top: 1rem;
    max-width: 60%;
}
footer a {
    color: white;
    text-decoration: none;
}
footer a:hover {
    color: white;
    text-decoration: none;
}
main {
    min-height: 71vh;
}
.bottom0 {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.homeSectionFirst {
    background-color: var(--verde);
}
.homeSectionLeft {
    color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.homeSectionLeft img {
    width: 70%;
}
.black-white {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.homeSectionRight {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 1rem;
    max-width: 90%;
    margin: 3rem auto;
}
.homeSectionRight li {
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
}

.modal-box {
    padding: 2rem 10%;
}
.form-image {
    height: 120px;
    width: 200px;
    object-fit: cover;
}
.form-image2 {
    height: 60px;
    width: 100px;
    object-fit: cover;
}

.img-teacher-list {
    width: 135px;
    height: 135px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
}
.img-teacher-detail {
    width: 135px;
    height: 135px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
}
.img-swiper-detail {
    width: 300px;
    height: 300px;
    object-fit: cover;
}
.img-teacher-home {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    float: right;
    margin-right: 1rem;
}
.text-teacher-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text-teacher-home h3 {
    font-size: 0.9rem;
    line-height: inherit;
    margin-top: 1rem;
}
.text-teacher-home h4 {
    font-size: 0.8rem;
    line-height: inherit;
    margin-top: 0;
}
.teacher-list-box {
    cursor: pointer;
}

.btn-outline-dark:hover {
    background-color: var(--verde);
    border-color: var(--verde);
    color: var(--negro);
}
.media-card-teacher {
    height: 130px;
}
.select-img-box {
    margin-top: 16px !important;
    padding: 15px 15px;
    width: 100%;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    cursor: pointer;
}
.select-img-box:hover {
    background-color: var(--verde);
}
.select-pdf-name {
    margin-top: 16px !important;
    padding: 15px 15px;
    width: 100%;
    text-align: center;
}
.page-buttons {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    align-items: center;
}
.give-suscription {
    margin-top: 1rem;
}
/* DESKTOP STYLES */
@media (min-width: 768px) {
    h1 {
        font-size: 2rem;
    }
    h3 {
        line-height: 2.5rem;
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1.3rem;
    }
    h1,
    h2,
    h3 {
        margin: 0 0 2rem 0;
        line-height: 1.5;
    }
    .main-header {
        padding-bottom: 70px;
    }
    .menuButton {
        display: none;
    }
    .text-teacher-home h3 {
        font-size: 1rem;
    }
    .text-teacher-home h4 {
        font-size: 1rem;
    }
    .homeSectionRight {
        height: 500px;
        margin: 0 auto;
    }
    .hero-img {
        padding: 0.5rem;
    }
    .navbar-main img {
        height: 40px;
        margin: 15px 0 15px 0;
    }
    .logo {
        margin-left: 40px !important;
    }
    .img-teacher-list {
        width: 250px;
        height: 250px;
        margin-bottom: 1.5rem;
    }
    .shadow-hover:hover {
        -webkit-box-shadow: 0px 0px 20px 8px rgba(17, 17, 17, 0.23);
        -moz-box-shadow: 0px 0px 20px 8px rgba(17, 17, 17, 0.23);
        box-shadow: 0px 0px 20px 8px rgba(17, 17, 17, 0.23);
    }
    .img-teacher-detail {
        width: 200px;
        height: 200px;
        margin-bottom: 2rem;
    }
    .text-teacher-detail {
        margin: auto 4rem;
    }
    .media-card-teacher {
        height: 170px;
    }
    .give-suscription {
        margin-top: 1.5rem;
    }
}

/* DATE PICKER */
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container input {
    width: 100%;
    padding: 15px 14px;
    margin-top: 16px;
    margin-bottom: 8px;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
}
.react-datepicker__input-container input:hover {
    border: 1px solid #111111;
}
/* PDF VIEWER */

.react-pdf__Document {
    max-width: 100%;
    max-height: 990px;
}

.react-pdf__Page__textContent {
    position: initial !important;
    height: auto !important;
}

.react-pdf__Page__canvas {
    border-radius: 8px;
    max-width: 700px;
    width: 90vw !important;
    height: auto !important;
    max-height: fit-content;
    display: block;
    background-color: white;
}
.pointer {
    cursor: pointer;
}
